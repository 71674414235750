<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="`pt-video-banner pt-video-banner__${type}`">
    <template v-if="checkType.section && infoVideoBanner">
      <div class="content-video">
        <client-only>
          <video v-lazy-load playsinline autoplay loop muted class="content-video__video">
            <source
              :src="infoVideoBanner.content.bg_media.url"
              :alt="infoVideoBanner.content.bg_media.alternativeText"
              type="video/mp4"
            />
          </video>
        </client-only>
      </div>
      <div class="video-overlay"></div>
      <div class="content-banner container">
        <div class="z-10 w-full">
          <Breadcrumbs
            :path="experimentalCenters"
            :section="$t('breadcrumbs.centros')"
            class="mt-11"
            color="white"
          />
          <div class="flex flex-col-reverse justify-center lg:flex-row h-2/3 md:h-3/4">
            <div class="w-full flex flex-col justify-center">
              <h1 class="content-banner__title" v-html="infoVideoBanner.content.title" />
              <h2 class="content-banner__subtitle" v-html="infoVideoBanner.content.description" />
            </div>
            <ul class="lg:ml-28 lg:justify-end">
              <li v-for="(item, index) in infoVideoBanner.logos" :key="index">
                <NuxtImg
                  :key="item.url"
                  class="w-36 first:mb-4 last:mb-10"
                  loading="lazy"
                  :src="item.url"
                  :alt="item.alternativeText"
                  :title="item.alternativeText"
                />
              </li>
            </ul>
          </div>
          <div class="groupAction">
            <div
              v-for="(item, index) in infoVideoBanner.links"
              :key="index"
              class="content-banner__play first:mr-10"
            >
              <p class="text-play">{{ item.label }}</p>
              <button
                class="box-play"
                :class="index === 0 ? 'play' : 'info'"
                type="button"
                @click="index === 0 ? $emit('playVideo', item.url) : $emit('scrollElement')"
              >
                <icon :name="item.icon" class="text-4xl text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="checkType.banner || checkType.card">
      <template v-if="setSources.length">
        <video
          v-for="(video, index) in setSources"
          v-show="index === indexVideo"
          :key="`${index}_${keyVideo}`"
          v-lazy-load
          class="content-video"
          muted
          autoplay
          playsinline
          :loop="setSources.length === 1"
          :src="video.url"
          type="video/mp4"
          preload="none"
          @ended="nextVideo($event)"
        />
      </template>
      <div v-else class="py-8 px-6 rounded bg-gray-dark">
        <NuxtImg src="/iconBannerFormula.svg" alt="nuestra-formula" />
        <div
          class="flex leading-[50px] flex-col text-4xl lg:text-[40px] font-ubuntu font-bold uppercase mt-7"
        >
          <span class="text-green-highlighted">{{ $t('banners.ourFormula.formula') }}</span>
          <span class="text-blue-light">{{ $t('banners.ourFormula.adn') }}</span>
          <span class="whitespace-pre-line text-white">{{
            $t('banners.ourFormula.waterSunEarth')
          }}</span>
          <span class="text-white">{{ $t('banners.ourFormula.people') }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumbs from '~/components/Breadcrumbs';
import { VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL } from '@/utils/enums';
export default {
  name: 'PtVideoBanner',
  components: { Breadcrumbs },
  props: {
    type: {
      type: String,
      default: 'section',
      validator: val => /section|banner|card/.test(val)
    },
    sources: {
      type: [Array, Object],
      default: () => []
    },
    infoVideoBanner: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      keyVideo: 0,
      indexVideo: 0,
      experimentalCenters: VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL.replace('/', '')
    };
  },
  computed: {
    checkType() {
      return {
        card: this.type === 'card',
        banner: this.type === 'banner',
        section: this.type === 'section'
      };
    },
    setSources() {
      return Array.isArray(this.sources) ? this.sources : [this.sources];
    }
  },
  mounted() {
    if (this.checkType.banner) window.addEventListener('visibilitychange', this.resetVideo);
  },
  unmounted() {
    if (this.checkType.banner) window.removeEventListener('visibilitychange', this.resetVideo);
  },
  methods: {
    nextVideo(e) {
      const video = e.target;
      video.pause();
      if (this.setSources.length - 1 > this.indexVideo) this.indexVideo++;
      else this.indexVideo = 0;
      video.currentTime = 0;
      video.load();
    },
    resetVideo() {
      this.indexVideo = 0;
      this.keyVideo++;
    }
  }
};
</script>
<style src="./style.scss" lang="scss" />
